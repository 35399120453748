import React, { useEffect, useState } from 'react';
import '../PopupInfo.css';

import Scene3D from './Scene3D';
import PopupInfo from './PopupInfo';
import ClickableIcon from './ClickableIcon';
import Gallery from './Gallery';
import DrawingGallery from './DrawingGallery';
import BabylonScene from './BabylonScene';
import LocationInfo from './LocationInfo';



const MainComponent: React.FC = () => {

  const galleryImages = [
   'img/Potkrovlje_01.jpg',
   'img/Potkrovlje_02.jpg',
   'img/Potkrovlje_03.jpg',
   'img/Potkrovlje_04.jpg',
   'img/Potkrovlje_05.jpg',
   'img/Potkrovlje_06.jpg',
   'img/Potkrovlje_07.jpg',
   'img/Potkrovlje_08.jpg',
  'img/Potkrovlje_09.jpg'
    // Add more image URLs as needed
  ];

  const galleryImages2 = [
    'img/Osnova_01.jpg',
    'img/Osnova_02.jpg',
    'img/Osnova_03.jpg'
    
     // Add more image URLs as needed
   ];

    function handleIconClick(): void {
        throw new Error('Function not implemented.');
      }

    return (
        <div className="App">
    
          <div className="right">
            <Scene3D />
          </div>
          <PopupInfo 
           // title="" 
           // description=""
          />

          <LocationInfo 
            title="Belgrade, Serbia (GMT+2)" 
            
          />
          <ClickableIcon 
            iconSrc={'img/icon_back.png'} 
            altText="Clickable Icon" 
            onClick={handleIconClick} 
          />

        <Gallery 
            iconSrc={'img/icon_gallery.png'} 
            altText="test" 
            images={galleryImages} 
          />

        <DrawingGallery 
            iconSrc={'img/icon_drawings.png'} 
            altText="test" 
            images={galleryImages2} 
          />
        </div>
      );
};

export default MainComponent;