// src/state/transparency/transparencySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TransparencyState {
  value: number;
}

const initialState: TransparencyState = {
  value: 0.5, // fully opaque (on)
};

const transparencySlice = createSlice({
  name: "transparency",
  initialState,
  reducers: {
    toggleTransparency: (state) => {
      state.value = state.value === 1.0 ? 0.5 : 1.0;
      console.log("trannsparencySLice : " + state.value);
    },
  },
});

export const { toggleTransparency } = transparencySlice.actions;
export default transparencySlice.reducer;
