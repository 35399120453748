// src/state/transparency/showAndHideSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ShowAndHideState {
  value: number;
}

const initialState: ShowAndHideState = {
  value: 1, // fully opaque (on)
};

const showAndHideSlice = createSlice({
  name: "showAndHide",
  initialState,
  reducers: {
    toggleVisibility: (state) => {
      state.value = state.value === 1.0 ? 0 : 1.0;
      console.log("showAndHideSlice : " + state.value);
    },
  },
});

export const { toggleVisibility } = showAndHideSlice.actions;
export default showAndHideSlice.reducer;
