// src/components/Scene3D.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Color3, CubeTexture, Engine, MeshBuilder, PBRMaterial, Scene, StandardMaterial, } from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import {
  ArcRotateCamera,
  HemisphericLight,
  Vector3,
} from '@babylonjs/core';


import CustomLoadingScreen from '../CustomLoadingScreen';
import { useDispatch, useSelector } from 'react-redux';

import { setElectricityTransparency, setFloorTransparency, setFurnitureTransparency, setHeatTransparency, setInitialTriggerTransparency, setInstallationTransparency, setProductTextures, setWaterTransparency, toogleDirectionalModelValue } from '../../state/transparency/floorsTransparencySlice';
import '../../App.css';
import DirectionalModel from '../DirectionalModel';
import GenericModel from '../GenericModel';
import { RootState } from '../../state/store';
import ProductGenericModel from './ProductGenericModel';
import { useLocation } from 'react-router-dom';


const ProductScene3D: React.FC = () => {
  const dispatch = useDispatch();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [scene, setScene] = useState<Scene | null>(null);
  const [camera, setCamera] = useState<ArcRotateCamera | null>(null);

  const location = useLocation();
  // Extract the pathname from the URL
  const pathname = location.pathname;
  // Split the pathname by '/' and get the last two segments
  const pathSegments = pathname.split('/').filter(Boolean); // filter(Boolean) to remove empty segments
  const projectCode = pathSegments[pathSegments.length - 2];
  const productCode = pathSegments[pathSegments.length - 1];

  const floorsTransparency= useSelector((state: RootState) => state.floorsTransparency.values);

  const furnitureVisibility = useSelector((state: RootState) => state.floorsTransparency.furnitureValues);
  const installationTransparency = useSelector((state: RootState) => state.floorsTransparency.installationValues);
  const heatTransparency = useSelector((state: RootState) => state.floorsTransparency.heatValues);
  const electricityTransparency = useSelector((state: RootState) => state.floorsTransparency.electricityValues);
  const waterTransparency = useSelector((state: RootState) => state.floorsTransparency.waterValues);

  const activeModelPopup = useSelector((state: RootState) => state.floorsTransparency.activeModelPopup);


  const productTextures = useSelector((state: RootState) => state.floorsTransparency.productTextures);

  const setProductTextureValue = (key:string, value: string) => {
    dispatch(setProductTextures({key,value})); 
  };

  const toogleDirectionalModel = (key:string) => {
    dispatch(toogleDirectionalModelValue(key)); 
  };

  const apartmentId = "Apartment-Demo";
  const [apartmentData, setApartmentData] = useState<{
    description: string;

  } | null>(null);

  const [infoModelData, setInfoModelData] = useState<{
    description: string;

  } | null>(null);

 



  // Use useEffect to programmatically click the button on component mount with a delay
  useEffect(() => {
    const timer = setTimeout(() => {

        //test
        //setProductTextureValue("body","KA0401_body_white.glb");
        //setProductTextureValue("front", "KA0401_front_white.glb");
        data.forEach((item: MainItem) => {
          if (item.Textures.length > 0) {
            // Get the last item in the Textures array
            const lastTexture = item.Textures[item.Textures.length - 1];
            setProductTextureValue(lastTexture.Model,lastTexture.TextureModel);
          }
          });
        //test

        setTransparencyForFirstFloor();
        //setTransparencyForSecondFloor();

        setFurnitureVisibilityForFirstFloor();
        setFurnitureVisibilityForSecondFloor();

        setInstallationVisibilityForFirstFloor();
        setInstallationVisibilityForSecondFloor();

        setInitialTrigger();

        toogleDirectionalModel("f11");
        toogleDirectionalModel("f12");
        toogleDirectionalModel("f13");
        toogleDirectionalModel("f14");
        toogleDirectionalModel("f15");


        toogleDirectionalModel("f21");
        toogleDirectionalModel("f22");

    }, 2500); // 1 second delay

    // Cleanup the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);


  
  const setInitialTrigger = () => {
    dispatch(setInitialTriggerTransparency({ value: true})); 
  };


  const setTransparencyForFirstFloor = () => {
    dispatch(setFloorTransparency({ floor: 1, value: floorsTransparency[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setTransparencyForSecondFloor = () => {
    dispatch(setFloorTransparency({ floor: 2, value: floorsTransparency[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };


  //Furniture
  const setFurnitureVisibilityForFirstFloor = () => {
    dispatch(setFurnitureTransparency({ floor: 1, value: furnitureVisibility[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setFurnitureVisibilityForSecondFloor = () => {
    dispatch(setFurnitureTransparency({ floor: 2, value: furnitureVisibility[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };

  //end Furniture

  //Installation
  const setInstallationVisibilityForFirstFloor = () => {
    dispatch(setInstallationTransparency({ floor: 1, value: installationTransparency[1] === 1 ? 0.5 : 1 })); // First floor (index 2)
  };

  const setInstallationVisibilityForSecondFloor = () => {
    dispatch(setInstallationTransparency({ floor: 2, value: installationTransparency[2] === 1 ? 0.5 : 1 })); // Second floor (index 2)
  };
  //END installation


  //Heat
  const setHeatVisibilityForFirstFloor = () => {
    dispatch(setHeatTransparency({ floor: 1, value: heatTransparency[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setHeatVisibilityForSecondFloor = () => {
    dispatch(setHeatTransparency({ floor: 2, value: heatTransparency[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };
  //END Heat


  //Electricity
  const setElectricityVisibilityForFirstFloor = () => {
    dispatch(setElectricityTransparency({ floor: 1, value: electricityTransparency[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setElectricityVisibilityForSecondFloor = () => {
    dispatch(setElectricityTransparency({ floor: 2, value: electricityTransparency[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };
  //END Electricity


  //Water
  const setWaterVisibilityForFirstFloor = () => {
    dispatch(setWaterTransparency({ floor: 1, value: waterTransparency[1] === 1 ? 0 : 1 })); // First floor (index 2)
  };

  const setWaterVisibilityForSecondFloor = () => {
    dispatch(setWaterTransparency({ floor: 2, value: waterTransparency[2] === 1 ? 0 : 1 })); // Second floor (index 2)
  };
  //END Water


  useEffect(() => {
    if (!canvasRef.current) return;

    const engine = new Engine(canvasRef.current, true);

    const scene = new Scene(engine);
    setScene(scene);

    const customLoadingScreen = new CustomLoadingScreen(scene, 'Loading...');
    engine.loadingScreen = customLoadingScreen;

    // Display the loading UI
    engine.displayLoadingUI();

    // Wait for 1.5 seconds and then hide the loading UI
    const timer = setTimeout(() => {
      engine.hideLoadingUI();
    }, 2350); // 1.5 seconds delay

    // Load HDRI and set it as the environment texture

    async function loadCubeTextures(scene: Scene): Promise<void> {
      // Helper function to load textures
      const loadTexture = async (url: string): Promise<CubeTexture> => {
          try {
              const texture = CubeTexture.CreateFromPrefilteredData(url, scene);
              await texture.onLoadObservable.addOnce(() => {
                  console.log(`${url} texture loaded.`);
              });
              return texture;
          } catch (error) {
              console.error(`Error loading texture from ${url}:`, error);
              throw error; // re-throw the error to be caught by the calling code
          }
      };
  
      try {
          // Load both textures asynchronously
          const [hdrTexture, pozadinaTexture] = await Promise.all([
              loadTexture("https://animodstorage.blob.core.windows.net/hdr/scene.env"),
              loadTexture("https://animodstorage.blob.core.windows.net/hdr/environment_gradient.env")
          ]);
  
          // Create skybox
          const skybox = MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, scene);
          const skyboxMaterial = new StandardMaterial("skyBox", scene);
          skyboxMaterial.backFaceCulling = false;
          skyboxMaterial.reflectionTexture = pozadinaTexture.clone();
          skyboxMaterial.reflectionTexture.coordinatesMode = 5;
          skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
          skyboxMaterial.specularColor = new Color3(0, 0, 0);
          skybox.material = skyboxMaterial;
  
          // Create a reflective cube
          const cube = MeshBuilder.CreateBox('cube', { size: 1000.0 }, scene);
          const cubeMaterial = new PBRMaterial('cube', scene);
          cubeMaterial.reflectionTexture = hdrTexture.clone();
          cubeMaterial.reflectionTexture.coordinatesMode = 3;
          cubeMaterial.cameraExposure = 0.66;
          cubeMaterial.cameraContrast = 1.66;
          cubeMaterial.microSurface = 0.96;
          cube.material = cubeMaterial;
  
          // Set the position of the cube
          cube.position = new Vector3(0, 1, 0); 
  
          // Set the environment texture
          scene.environmentTexture = hdrTexture;
        } catch (error) {
            console.error("Error loading textures:", error);
        }
    }
  
    // Call the loadCubeTextures function to load and apply the textures
    const handleHdr = async () => {
      await loadCubeTextures(scene);
    }

    handleHdr();
        
    const isMobile = window.innerWidth <= 768; // Adjust the width based on your needs

    const camera = new ArcRotateCamera(
      'camera1',
      Math.PI / 1.3,  // More from the side
      Math.PI / 4,  // Higher angle
      2.5,           // Distance from the target
      Vector3.Zero(),
      scene
    );

    if (isMobile) {
      // Zoom out more on mobile by increasing the radius
      camera.radius = 5; // Set a larger value for mobile
      camera.pinchPrecision = 300;
    } else {
      camera.radius = 2.5; // Set a normal value for desktop
      camera.pinchPrecision = 10;
    }

    camera.useAutoRotationBehavior = true;
    camera.attachControl(canvasRef.current, true);

    // Adjust scroll sensitivity
    //camera.wheelDeltaPercentage = 0.01; // Smaller values mean slower zoom
    camera.wheelPrecision = 600; // Larger values mean slower zoom
    camera.panningSensibility = 2300; // Increase this value to slow down panning
    camera.noRotationConstraint = true;
    //camera.checkCollisions = true;
    //camera.collisionRadius = new Vector3(1.3, 1.3, 1.3);

    scene.collisionsEnabled = true;


    camera.minZ =0.3;
    camera.lowerRadiusLimit =1.3;
    camera.upperRadiusLimit = 6;
    setCamera(camera);

    const light = new HemisphericLight('light1', new Vector3(0, 1, 0), scene);

    engine.runRenderLoop(() => {
      scene.render();
    });

    const handleResize = () => {
      engine.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      engine.dispose();
    };
  }, []);

  let eyeClosed = "img/eye-closed.png";
  let eyeOpen = "img/eye-open.png";
  let iconElectricity = "img/icon_electricity.png";
  let iconWater = "img/icon_water.png"
  let iconHeat = "img/icon_heat.png"
  let iconFurniture = "img/icon_furniture.png"
  let firstFloor = "img/icon_first.png";
  let secondFloor = "img/icon_second.png";

  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({
    'Floor 1': true,  // Item 1 is expanded by default
    'Floor 2': false, // Item 2 is collapsed by default
  });

  // Initialize checked subitems with desired defaults
  const [checkedSubItems, setCheckedSubItems] = useState<{ [key: string]: boolean }>({
    'Floor 1-Furniture': true,  // Subitem 1.1 under Item 1 is checked by default
    'Floor 1-Electricity': false, // Subitem 1.2 under Item 1 is unchecked by default
    'Floor 1-Water': false,  // Subitem 2.1 under Item 2 is checked by default
    'Floor 1-Heat': false, // Subitem 2.2 under Item 2 is unchecked by default
    'Floor 2-Furniture': true,  // Subitem 1.1 under Item 1 is checked by default
    'Floor 2-Electricity': false, // Subitem 1.2 under Item 1 is unchecked by default
    'Floor 2-Water': false,  // Subitem 2.1 under Item 2 is checked by default
    'Floor 2-Heat': false, // Subitem 2.2 under Item 2 is unchecked by default
  });

  const items = [
    {
      name: 'Floor 1',
      icon: firstFloor,
      subItems: [
        { name: 'Furniture', icon: iconFurniture },
        { name: 'Electricity', icon: iconElectricity },
        { name: 'Water', icon: iconWater },
        { name: 'Heat', icon: iconHeat },
      ],
    },
    {
      name: 'Floor 2',
      icon: secondFloor,
      subItems: [
        { name: 'Furniture', icon: iconFurniture },
        { name: 'Electricity', icon: iconElectricity },
        { name: 'Water', icon: iconWater },
        { name: 'Heat', icon: iconHeat },
      ],
    },
  ];


  const productItems = [
    {
      name: 'body',
      icon: firstFloor,
      subItems: [
        { name: 'KA0301_body_oak.glb', icon: iconFurniture },
        { name: 'KA0301_body_walnut.glb', icon: iconElectricity },
        { name: 'Water', icon: iconWater },
        { name: 'Heat', icon: iconHeat },
      ],
    },
    {
      name: 'Floor 2',
      icon: secondFloor,
      subItems: [
        { name: 'KA0301_front_azureblue.glb', icon: iconFurniture },
        { name: 'KA0301_front_white.glb', icon: iconElectricity },
        { name: 'Water', icon: iconWater },
        { name: 'Heat', icon: iconHeat },
      ],
    },
  ];
 

  const showTexture = (itemName: string) => {
    console.log("clicked on showTexture " + itemName);

    if(itemName === "Texture 1"){
      setProductTextureValue("body","KA0301_body_walnut.glb");
    }
    else if(itemName ==="Texture 2"){
      setProductTextureValue("body","KA0301_body_oak.glb");
    }

    else if(itemName ==="Texture 3"){
      setProductTextureValue("front","KA0301_front_white.glb");
    }

    else if(itemName ==="Texture 4"){
      setProductTextureValue("front","KA0301_front_azureblue.glb");
    }
  };


  {/*const handleItemClick = (itemName: string) => {
    setExpandedItems(prevExpandedItems => {
      const isExpanded = !prevExpandedItems[itemName];
  
      //console.log(`Main Item: ${itemName}, Expanded: ${isExpanded}`);
  
      // Schedule the state update after the render phase
      setTimeout(() => {
        if(itemName === "Floor 1"){
          setTransparencyForFirstFloor();
        } else if(itemName === "Floor 2"){
          setTransparencyForSecondFloor();
        }
      }, 0);
  
      return {
        ...prevExpandedItems,
        [itemName]: isExpanded,
      };
    });
  }; */}

  const handleSubItemClick = (mainItemName: string, subItemName: string) => {
    setCheckedSubItems(prevCheckedSubItems => {
      const isChecked = !prevCheckedSubItems[`${mainItemName}-${subItemName}`];
  
      //console.log(`Main Item: ${mainItemName}, Subitem: ${subItemName}, Checked: ${isChecked}`);
  
      // Schedule the state update after the render phase
      setTimeout(() => {
        if(mainItemName === "Floor 1"){
          if(subItemName === "Furniture"){
            setFurnitureVisibilityForFirstFloor();
          } else if(subItemName === "Electricity"){
            setElectricityVisibilityForFirstFloor();
          } else if(subItemName === "Water"){
            setWaterVisibilityForFirstFloor();
          } else if(subItemName === "Heat"){
            setHeatVisibilityForFirstFloor();
          }
        } else if(mainItemName === "Floor 2"){
          if(subItemName === "Furniture"){
            setFurnitureVisibilityForSecondFloor();
          } else if(subItemName === "Electricity"){
            setElectricityVisibilityForSecondFloor();
          } else if(subItemName === "Water"){
            setWaterVisibilityForSecondFloor();
          } else if(subItemName === "Heat"){
            setHeatVisibilityForSecondFloor();
          }
        }
      }, 0);
  
      return {
        ...prevCheckedSubItems,
        [`${mainItemName}-${subItemName}`]: isChecked,
      };
    });
  };


  //test
  interface Texture {
    Name: string;
    Icon: string;
    TextureModel: string;
    Model: string;
    
  }
  
  interface MainItem {
    ItemName: string;
    Textures: Texture[];
  }
  
  interface Product {
    ProductId: number;
    Name: string;
    Code: string;
    Description: string;
    Image: string;
  }

  interface Model {
    id: number;
    name: string;
    model: string;
    productId: number;
    projectId: number;
  }

  const [data, setData] = useState<MainItem[]>([]);
  const [firstProduct, setFirstProduct] = useState<Product | null>(null);
  const [models, setModels] = useState<Model[]>([]);

// Fetch models data via AJAX
useEffect(() => {
  const fetchModels = async () => {
    try {
      const response = await fetch(`https://animodapi.azurewebsites.net/Model/GetProductModels?projectName=${projectCode}&productCode=${productCode}`);// Replace with your actual API URL
      const dataResponse = await response.json();
      setModels(dataResponse);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  fetchModels();
}, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://animodapi.azurewebsites.net/Filter?projectName=${projectCode}&productCode=${productCode}`);// Replace with your actual endpoint
        const dataResponse = await response.json();

         // Extract and set the first product
         const firstProductData = dataResponse.length > 0 ? dataResponse[0].product : null;
         setFirstProduct(firstProductData ? {
           ProductId: firstProductData.productId,
           Name: firstProductData.name,
           Code: firstProductData.code,
           Description: firstProductData.description,
           Image: firstProductData.image,
         } : null);


        const transformedData = dataResponse.map((item: any) => ({
          ItemName: item.itemName.toLowerCase(),
          Textures: item.filterItems.map((texture: any) => ({
            Name: texture.name,
            Icon: texture.icon,
            TextureModel: texture.textureModel,
            Model: texture.model,
          })),
        }));
        setData(transformedData);


         // Set the first "model" to selectedItem
         const firstModel = transformedData[0]?.Textures[0]?.Model || "";
         setSelectedItem(firstModel);
 
        // Set the last TextureModel for each filter group to selectedTextures
        const initialTextures: Record<string, string | null> = {};
        transformedData.forEach((item: MainItem) => {
        if (item.Textures.length > 0) {
          // Get the last item in the Textures array
          const lastTexture = item.Textures[item.Textures.length - 1];
          initialTextures[item.ItemName] = lastTexture.TextureModel;
        }
        });
        setSelectedTextures(initialTextures);

      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);


  {/*const data: MainItem[] = [
    {
      ItemName: "body",
      Textures: [
        { Name: "walnut", Icon: "/img/walnut_icon.jpg", TextureModel:"KA0301_body_walnut.glb", Model:"body" },
        { Name: "oak", Icon: "/img/oak_icon.jpg", TextureModel:"KA0301_body_oak.glb",Model:"body" },
      ],
    },
    {
      ItemName: "front",
      Textures: [
        { Name: "blue", Icon: "/img/azure_blue_icon.jpg", TextureModel:"KA0301_front_azureblue.glb",Model:"front" },
        { Name: "white ", Icon: "/img/white_icon.jpg", TextureModel:"KA0301_front_white.glb", Model:"front" },
      ],
    },
  ]; */}


  {/*const [selectedItem, setSelectedItem] = useState<string>("body");
  const [selectedTextures, setSelectedTextures] = useState<Record<string, string | null>>({
    body: "KA0301_body_oak.glb",
    front: "KA0301_front_white.glb",
  }); */}

  const [selectedItem, setSelectedItem] = useState<string>("");
  const [selectedTextures, setSelectedTextures] = useState<Record<string, string | null>>({});



  const handleItemClick = (itemName: string) => {
    setSelectedItem(itemName);
    //setSelectedTexture(null); // Reset texture selection when a new item is selected
  };

  const getSelectedTextures = () => {
    const selected = data.find((item) => item.ItemName === selectedItem);
    return selected ? selected.Textures : [];
  };






    // Custom method to handle texture click
    const handleTextures = (model: string, textureName: string) => {
      setSelectedTextures((prev) => ({
        ...prev,
        [selectedItem]: textureName, // Store the selected texture for the current MainItem
      }));
      console.log("Texture clicked:", model, textureName);

      setProductTextureValue(model,textureName);
  
    };



  // Zoom In Handler
  const handleZoomIn = () => {
    if (camera ) {
      camera.radius -= 1; // Adjust the zoom speed as needed
      if (camera.radius < 2) {
        camera.radius = 2; // Prevent zooming too close
      }
    }
  };

  // Zoom Out Handler
  const handleZoomOut = () => {
    if (camera) {
      camera.radius += 1; // Adjust the zoom speed as needed
      if (camera.radius > 50) {
        camera.radius = 50; // Prevent zooming too far
      }
    }
  };


  //end test



  return (
    <>
      <canvas ref={canvasRef} />
      {scene && camera && (
        <>
          <GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="Grid.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" hasEnvironment ={false}/> 
          {/*<GenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="KA0201_base.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" /> */}


          {/*
          <ProductGenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="KA0301_body_oak.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" productModel="body" /> 
          <ProductGenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="KA0301_body_walnut.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" productModel="body" /> 
          
          <ProductGenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="KA0301_front_azureblue.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" productModel="front" /> 
          <ProductGenericModel scene={scene} camera ={camera} canvas={canvasRef.current} model="KA0301_front_white.glb" floor = {1} hasFurniture ={false} hasInstallation = {false} hasHeat={false} hasElectricity ={false} hasWater= {false} isDirectional={false} targetedModel="" productModel="front" /> */}


{models.map((model) => (
        <GenericModel
          key={model.id}
          scene={scene}
          camera={camera}
          canvas={canvasRef.current}
          model={model.model}
          floor={1}
          hasFurniture={false}
          hasInstallation={false}
          hasHeat={false}
          hasElectricity={false}
          hasWater={false}
          isDirectional={false}
          targetedModel=""
          hasEnvironment ={false}
        />
      ))}

         
          {data.map((mainItem, index) => (
                <div key={index}>
                  {mainItem.Textures.map((texture, idx) => (
                    <ProductGenericModel
                      key={idx}
                      scene={scene}
                      camera={camera}
                      canvas={canvasRef.current}
                      model={texture.TextureModel || ""}
                      floor={1}
                      hasFurniture={false}
                      hasInstallation={false}
                      hasHeat={false}
                      hasElectricity={false}
                      hasWater={false}
                      isDirectional={false}
                      targetedModel=""
                      productModel={texture.Model || ""}
                    />
                  ))}
                </div>
          ))}


        </>
      )}
    {/* <div className="expandable-list">
      <div className="item">
        <button className="item-button">
          <img src="img/icon_first.png" alt="Item Icon" className="item-icon" />Body
          <span className="expand-icon expanded">▼</span></button>
          <div className="subitems">
            
            <div className="subitem-container">
              <a className="subitem"><img src="img/icon_furniture.png" alt="Subitem Icon" className="subitem-icon"/>Texture 1</a><img src="img/eye-open.png" alt="Eye Icon" className="eye-icon" onClick={() => showTexture("Texture 1")}/>
            </div>
              <div className="subitem-container">
                <a className="subitem"><img src="img/icon_electricity.png" alt="Subitem Icon" className="subitem-icon"/>Texture 2</a><img src="img/eye-closed.png" alt="Eye Icon" className="eye-icon" onClick={() => showTexture("Texture 2")} />
              </div>
              
              
          </div>
        </div>
                    
        


        <div className="item">
        <button className="item-button">
          <img src="img/icon_first.png" alt="Item Icon" className="item-icon" />Front
          <span className="expand-icon expanded">▼</span></button>
          <div className="subitems">
            
            <div className="subitem-container">
              <a className="subitem"><img src="img/icon_furniture.png" alt="Subitem Icon" className="subitem-icon"/>Texture 3</a><img src="img/eye-open.png" alt="Eye Icon" className="eye-icon" onClick={() => showTexture("Texture 3")}/>
            </div>
              <div className="subitem-container">
                <a className="subitem"><img src="img/icon_electricity.png" alt="Subitem Icon" className="subitem-icon"/>Texture 4</a><img src="img/eye-closed.png" alt="Eye Icon" className="eye-icon" onClick={() => showTexture("Texture 4")} />
              </div>
              
              
          </div>
        </div>
          
          
          
          
          
          
    </div> */}





<div className="container">
    {/* Add the product name at the top-right */}
    <div className="product-name">{firstProduct ? firstProduct.Name : ""}</div>
      <div className="main-items">
        {data.map((item) => (
          <div
            key={item.ItemName}
            className={`main-item ${
              selectedItem === item.ItemName ? "selected" : ""
            }`}
            onClick={() => handleItemClick(item.ItemName)}
          >
            {item.ItemName}
          </div>
        ))}
      </div>

      <div className="textures">
        {getSelectedTextures().map((texture) => (
          <div key={texture.Name}   className={`texture ${
            selectedTextures[selectedItem] === texture.TextureModel ? "highlighted" : ""
          }`}
        >
            <img
              src={texture.Icon}
              alt={texture.Name}
              onClick={() => handleTextures(texture.Model, texture.TextureModel)} // Call handleTextures on click
            />
            <p>{texture.Name}</p>
          </div>
        ))}
      </div>
         {/* Bottom-right PNG icon */}
      <img
        src="/img/animod_logo.png"  
        alt="Bottom-right Icon"
        className="bottom-right-icon"
      />

    </div>

        
 {/* Zoom Buttons */}
 <div
        style={{
          position: 'absolute',
          top: '360px', // Changed from 'top' to 'bottom'
          right: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <button
          onClick={handleZoomIn}
          style={{
            width: '40px',
            height: '40px',
            marginBottom: '10px',
            fontSize: '24px',
            cursor: 'pointer',
          }}
          aria-label="Zoom In"
        >
          +
        </button>
        <button
          onClick={handleZoomOut}
          style={{
            width: '40px',
            height: '40px',
            fontSize: '24px',
            cursor: 'pointer',
          }}
          aria-label="Zoom Out"
        >
          -
        </button>

</div>

    </>


        


  );
};

export default ProductScene3D;
