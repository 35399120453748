import React, { useEffect, useState } from 'react';
import '../PopupInfo.css';

// Define the structure for each button
interface ButtonData {
  id: string;
  defaultImage: string;
  activeImage: string;
  title: string;
}

// Props are no longer needed as the component manages its own state
interface PopupInfoProps { }

const PopupInfo: React.FC<PopupInfoProps> = () => {
  // Define your buttons here. Update the image paths as necessary.
  const buttons: ButtonData[] = [
    {
      id: 'popup_info',
      defaultImage: '/img/icon_info_0.png',
      activeImage: '/img/icon_info.png',
      title: 'General Info',
    },
    {
      id: 'popup_energy',
      defaultImage: '/img/icon_energy_0.png',
      activeImage: '/img/icon_energy.png',
      title: 'Energy class',
    },
    {
      id: 'popup_electricity',
      defaultImage: '/img/icon_popup_electricity_0.png',
      activeImage: '/img/icon_popup_electricity.png',
      title: 'Electricity',
    },
    // Add more buttons as needed
  ];

  const apartmentId = "Apartment-Demo";

  // State to track the currently active button
  const [activeButtonId, setActiveButtonId] = useState<string | null>(null);

  let testingActiveButtonId = "cam_01_dot";

  // State to store the fetched data for the active model
  const [modelData, setModelData] = useState<{ description: string } | null>(null);

  // Fetch data based on the active button
  useEffect(() => {
    if (!activeButtonId) {
      setModelData(null); // Clear data when no button is active
      return;
    }

    const fetchModelData = async () => {
      try {
        const response = await fetch(`https://animodapi.azurewebsites.net/Model/GetModel?id=${activeButtonId}`);
        const data = await response.json();
        setModelData({
          description: data.description,
        });
      } catch (error) {
        console.error('Error fetching model data:', error);
      }
    };

    fetchModelData();
  }, [activeButtonId]);

  // Function to handle button clicks
  const handleButtonClick = (id: string) => {
    setActiveButtonId(prevId => (prevId === id ? null : id));
  };

  return (
    <div className="popup-info-container">
      {/* Popup Info */}
      {activeButtonId && modelData && (
        <div className="popup-info">
          <h3 className="popup-info-title">
            {buttons.find(button => button.id === activeButtonId)?.title}
          </h3>
          <p
            className="popup-info-description"
            dangerouslySetInnerHTML={{ __html: modelData.description }}
          />
        </div>
      )}

      {/* Buttons */}
      <div className="popup-buttons">
        {buttons.map(button => (
          <button
            key={button.id}
            className={`popup-button ${activeButtonId === button.id ? 'active' : ''}`}
            onClick={() => handleButtonClick(button.id)}
          >
            <img
              src={activeButtonId === button.id ? button.activeImage : button.defaultImage}
              alt={button.title}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default PopupInfo;
