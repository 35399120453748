import React, { useEffect, useState } from 'react';
import '../PopupInfo.css';
import { RootState } from '../state/store';
import { useDispatch, useSelector } from 'react-redux';
import { ActionManager, ArcRotateCamera, Color3, DynamicTexture, EasingFunction, ExecuteCodeAction, FreeCamera, Mesh, MeshBuilder, Scene, SineEase, StandardMaterial, TransformNode, Vector3 } from '@babylonjs/core';
import { Animation as BABYLONAnimation } from "@babylonjs/core/Animations/animation";
import { setActiveDirectionalModel, setInitialActiveModelPopup, setisDirectionalBtnClickedTransparency, toogleDirectionalModelValue } from '../state/transparency/floorsTransparencySlice';

interface DirectionalModelProps {
    scene: Scene;
    camera:ArcRotateCamera ,
    canvas: HTMLCanvasElement | null,
    targetedModel: string,
    directionalPositionMesh: string,
    directionalModelKey :string,
    floor:number;
}

const DirectionalModel : React.FC<DirectionalModelProps> = (props) => {
    
    const dispatch = useDispatch();
   
    const [isVisible, setIsVisible] = useState(false);
    const [discPopup, setDiscPopup] = useState<Mesh | null>(null);

    const directionalModelValues = useSelector((state: RootState) => state.floorsTransparency.directionalModelValues);
    const floorsTransparency= useSelector((state: RootState) => state.floorsTransparency.values);
    const activeDirectionalModel = useSelector((state: RootState) => state.floorsTransparency.activeDirectionalModel);

    const setCurrentActiveDirectionalModel= (directionalModel: string) => {
      dispatch(setActiveDirectionalModel(directionalModel));
    };

    const toogleDirectionalModel = (directionalModel: string) => {
      dispatch(toogleDirectionalModelValue(directionalModel)); // First floor (index 2)
    };


    const setActiveModelPopup = (activeModel: string) => {
      dispatch(setInitialActiveModelPopup(activeModel)); // First floor (index 2)
    };


    const setisDirectionalBtnClickedToFalse= () => {
      dispatch(setisDirectionalBtnClickedTransparency({ value: false})); 
    };

    const isDirectionalBtnClicked = useSelector((state: RootState) => state.floorsTransparency.isDirectionalBtnClicked);

    useEffect(() => {
    // Assuming props.scene is your Babylon.js scene
    // Create a TransformNode for positioning the popup
    const transformNode = new TransformNode("popupTransformNode", props.scene);

    // Get the mesh for positioning (replace with your specific mesh name)
    let positionDirectionalModel = props.scene.getMeshByName(props.directionalPositionMesh);
    if (positionDirectionalModel) {
      transformNode.position = positionDirectionalModel.getAbsolutePosition();
      // Adjust the position if needed
      // transformNode.position.y += 7;
    }

    // Create a disc mesh to replace the Ellipse
    const disc = MeshBuilder.CreateDisc("popupDisc", { radius: 0.2, tessellation: 32,sideOrientation: Mesh.DOUBLESIDE }, props.scene);

    // Apply material to the disc to prevent reflections
    const discMaterial = new StandardMaterial("discMaterial", props.scene);
    // Apply the image texture to the disc's material

    discMaterial.diffuseColor = new Color3(0.333, 0.722, 0.965);// Red color
    discMaterial.specularColor = new Color3(0, 0, 0); // No specular highlights (no shiny effect)
    discMaterial.reflectionTexture = null; // Disable reflections
    discMaterial.emissiveColor = new Color3(0, 0, 0); // Optional: make the disc glow (ignore lighting)

    disc.material = discMaterial;

    // Link the disc to the transform node so it follows the position
    disc.parent = transformNode;

      // Set billboard mode so the disc always faces the camera
    disc.billboardMode = Mesh.BILLBOARDMODE_ALL;

    // Apply rotation to flip the disc
    disc.rotation = new Vector3(Math.PI, 0, 0); // Rotate 180 degrees around the X-axis

    // Create a DynamicTexture for adding text to the disc
    const dynamicTexture = new DynamicTexture("dynamicTexture", { width: 256, height: 256 }, props.scene, false);
    dynamicTexture.hasAlpha = true; // Ensure alpha is enabled for transparency
    dynamicTexture.drawText("▼", null, null, "164px Arial", "white", "transparent", true);

    // Apply the dynamic texture to the disc's material
    discMaterial.diffuseTexture = dynamicTexture; 

    // Add the pointer click event on the disc mesh
    disc.actionManager = new ActionManager(props.scene);
    disc.actionManager.registerAction(
    new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
          processDirectionalModelValues(props.floor);
          const camera = props.scene.activeCamera;
  
          let target = props.scene.getMeshByName(props.targetedModel);
  
          if(camera instanceof ArcRotateCamera){
          if(target){
            const boundingBox = target.getBoundingInfo().boundingBox;
                  
            const center = boundingBox.centerWorld;
            const size = boundingBox.extendSizeWorld;
            const radius = Math.max(size.x, size.y, size.z);
    
            // Calculate the new radius
            const newRadius = radius / 2; // Adjust the radius as needed
    
            // Create animations
            const radiusAnimation = new BABYLONAnimation("cameraRadiusAnim", "radius", 60, BABYLONAnimation.ANIMATIONTYPE_FLOAT, BABYLONAnimation.ANIMATIONLOOPMODE_CONSTANT);
    
            // Set the keyframes for the animations
            radiusAnimation.setKeys([
              { frame: 0, value: camera.radius },
              { frame: 120, value: newRadius }
            ]);
    
            // Calculate new target position with y offset
            const newTarget = new Vector3(center.x, center.y , center.z);
    
            // Create target animation
            const targetAnimation = new BABYLONAnimation("cameraTargetAnim", "target", 60, BABYLONAnimation.ANIMATIONTYPE_VECTOR3, BABYLONAnimation.ANIMATIONLOOPMODE_CONSTANT);
    
            // Set the keyframes for the target animation
            targetAnimation.setKeys([
              { frame: 0, value: camera.target.clone() },
              { frame: 120, value: newTarget }
            ]);
    
            // Create easing function
            const easingFunction = new SineEase();
            easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    
            // Apply the easing function to the animations
            radiusAnimation.setEasingFunction(easingFunction);
            targetAnimation.setEasingFunction(easingFunction);
    
            // Start the animations
            camera.animations = [radiusAnimation, targetAnimation];
            props.scene.beginAnimation(camera, 0, 120, false, 1, () => {
              // Ensure the final radius and target are exactly set
              camera.radius = newRadius;
              camera.target = newTarget;
    
            // Calculate alpha and beta values to look at the wall parallel to the ground
            const newAlpha = Math.atan2(newTarget.z - camera.position.z, newTarget.x - camera.position.x);
            const newBeta = Math.PI / 2; // Parallel to the ground
    
            // Create animations for alpha and beta
            const alphaAnimation = new BABYLONAnimation("cameraAlphaAnim", "alpha", 60, BABYLONAnimation.ANIMATIONTYPE_FLOAT, BABYLONAnimation.ANIMATIONLOOPMODE_CONSTANT);
            alphaAnimation.setKeys([
              { frame: 0, value: camera.alpha },
              { frame: 60, value: newAlpha }
            ]);
    
            const betaAnimation = new BABYLONAnimation("cameraBetaAnim", "beta", 60, BABYLONAnimation.ANIMATIONTYPE_FLOAT, BABYLONAnimation.ANIMATIONLOOPMODE_CONSTANT);
            betaAnimation.setKeys([
              { frame: 0, value: camera.beta },
              { frame: 60, value: newBeta }
            ]);
    
            // Apply the easing function to the animations
            alphaAnimation.setEasingFunction(easingFunction);
            betaAnimation.setEasingFunction(easingFunction);
    
            // Start the alpha and beta animations
            camera.animations = [alphaAnimation, betaAnimation];
            props.scene.beginAnimation(camera, 0, 60, false, 1, () => {
              // Ensure the final alpha and beta are exactly set
              camera.alpha = newAlpha;
              camera.beta = newBeta;
    
              camera.checkCollisions = true;
              camera.panningSensibility = 0;
    
              // Universal Camera
              // Capture final position and orientation
                const finalPosition = camera.position.clone();
                const finalTarget = camera.target.clone();
    
                // Create and set new UniversalCamera
                const universalCamera = new FreeCamera("UniversalCamera", finalPosition, props.scene);
                universalCamera.fov = Math.PI / 3;
                universalCamera.setTarget(finalTarget);
                universalCamera.attachControl(props.canvas, true);
    
                // Enable collision checking
                universalCamera.checkCollisions = true;
                //universalCamera.applyGravity = true;
                universalCamera.ellipsoid = new Vector3(0.2, 0.2, 0.2); // Adjust the ellipsoid as needed
                universalCamera.ellipsoidOffset = new Vector3(0, 0, 0); // Adjust the offset as needed
                universalCamera.minZ = 0.1; 
                
                // Adjust the speed and inertia for smooth, slow movement
                universalCamera.speed = 0.1; // Set a very low speed for slow movement
                universalCamera.inertia = 0.7; // Increase inertia for smooth deceleration
    
                // Optional: Adjust angular sensibility for smoother rotation
                universalCamera.angularSensibility = 2000; // Increase to slow down rotation speed
    
                // Optional: Adjust the move sensibility (for keyboard input)
                universalCamera.keysUpward = [32]; // Space key to move up
                universalCamera.keysDownward = [16]; // Shift key to move down
                universalCamera.keysUp = [87]; // W key to move forward
                universalCamera.keysDown = [83]; // S key to move backward
                universalCamera.keysLeft = [65]; // A key to move left
                universalCamera.keysRight = [68]; // D key to move right
  
                // Set the new camera as the active camera
                props.scene.activeCamera = universalCamera;  
    
                setActiveModelPopup(props.targetedModel);
                setCurrentActiveDirectionalModel(props.directionalModelKey);
            });
          });
        } 
      }

      })
    );

    // Store disc in state
      setDiscPopup(disc);

    return () => {
      // Clean up when the component is unmounted
      disc.dispose();
    };
  }, [isVisible]);
  
    useEffect(() => {
      let isVisibleDirectional = directionalModelValues[props.directionalModelKey];
      setIsVisible(isVisibleDirectional === 1 ? true : false);
    }, [directionalModelValues]);

    useEffect(() => {
      // Only proceed if both `discPopup` and `isVisible` have been set
      if (discPopup && typeof isVisible !== "undefined") {
        discPopup.isVisible = isVisible;
      }
    }, [discPopup, isVisible]); // Also depend on `discPopup`
    
    useEffect(() => {
      if (discPopup) {
        discPopup.isVisible = floorsTransparency[props.floor] === 1 && directionalModelValues[props.directionalModelKey] === 1  ? true : false;
      }
    }, [floorsTransparency,discPopup]);


  useEffect(() => {

      if(isDirectionalBtnClicked === true && props.directionalModelKey === activeDirectionalModel){
        const camera = props.scene.activeCamera;

        if(camera instanceof FreeCamera){
        
        const finalPosition = camera.position.clone();
        const finalTarget = camera.target.clone();
        // Create and set new UniversalCamera
        const universalCamera = new FreeCamera("UniversalCamera", finalPosition, props.scene);
        universalCamera.setTarget(finalTarget);
        universalCamera.attachControl(props.canvas, true);

        // Enable collision checking
        universalCamera.checkCollisions = true;
        //universalCamera.applyGravity = true;
        universalCamera.ellipsoid = new Vector3(0.2, 0.2, 0.2); // Adjust the ellipsoid as needed
        universalCamera.ellipsoidOffset = new Vector3(0, 0, 0); // Adjust the offset as needed
        universalCamera.minZ = 0.1; 
        
        // Adjust the speed and inertia for smooth, slow movement
        universalCamera.speed = 0.1; // Set a very low speed for slow movement
        universalCamera.inertia = 0.7; // Increase inertia for smooth deceleration

        // Optional: Adjust angular sensibility for smoother rotation
        universalCamera.angularSensibility = 2000; // Increase to slow down rotation speed

        // Optional: Adjust the move sensibility (for keyboard input)
        universalCamera.keysUpward = [32]; // Space key to move up
        universalCamera.keysDownward = [16]; // Shift key to move down
        universalCamera.keysUp = [87]; // W key to move forward
        universalCamera.keysDown = [83]; // S key to move backward
        universalCamera.keysLeft = [65]; // A key to move left
        universalCamera.keysRight = [68]; // D key to move right

        const cameraa = new ArcRotateCamera(
          'cameraNew',
          Math.PI / 1.3,  // More from the side
          Math.PI / 4,  // Higher angle
          12.8,           // Distance from the target
          Vector3.Zero(),
          props.scene
        );

        cameraa.wheelPrecision = 150; // Larger values mean slower zoom
        cameraa.noRotationConstraint = true;


        setTimeout(() => {
            animateCameraTransition(props.scene, universalCamera, cameraa);
        }, 100);

          setActiveModelPopup("");
          setisDirectionalBtnClickedToFalse();

          let clikcedFromFloor = extractFloor(activeDirectionalModel);

          processDirectionalModelValues(clikcedFromFloor);
      }
    }


  }, [isDirectionalBtnClicked]);


  const animateCameraTransition = (scene: Scene, fromCamera: FreeCamera, toCamera: ArcRotateCamera) => {
    const alphaAnimKeys = [];
    alphaAnimKeys.push({
        frame: 0,
        value: fromCamera.rotation.y
    });

    alphaAnimKeys.push({
        frame: 100,
        value: Math.PI / 2 // Final alpha value (adjust as needed)
    });

    const alphaAnimation = new BABYLONAnimation("alphaAnimation", "alpha", 30, BABYLONAnimation.ANIMATIONTYPE_FLOAT, BABYLONAnimation.ANIMATIONLOOPMODE_CONSTANT);
    alphaAnimation.setKeys(alphaAnimKeys);

    const betaAnimKeys = [];
    betaAnimKeys.push({
        frame: 0,
        value: fromCamera.rotation.x
    });

    betaAnimKeys.push({
        frame: 100,
        value: Math.PI / 2.5 // Final beta value (adjust as needed)
    });

    const betaAnimation = new BABYLONAnimation("betaAnimation", "beta", 30, BABYLONAnimation.ANIMATIONTYPE_FLOAT, BABYLONAnimation.ANIMATIONLOOPMODE_CONSTANT);
    betaAnimation.setKeys(betaAnimKeys);

    scene.stopAnimation(fromCamera);

    // Temporarily switch the active camera to ArcRotateCamera for rotation animation
    toCamera.attachControl(props.canvas, true);
    scene.activeCamera = toCamera;

    const positionAnimKeys = [];

    positionAnimKeys.push({
        frame: 0,
        value: fromCamera.position.clone()
    });

    positionAnimKeys.push({
        frame: 100,
        value: new Vector3(toCamera.position.x, toCamera.position.y, toCamera.position.z)
    });

    const positionAnimation = new BABYLONAnimation("positionAnimation", "position", 30, BABYLONAnimation.ANIMATIONTYPE_VECTOR3, BABYLONAnimation.ANIMATIONLOOPMODE_CONSTANT);
    positionAnimation.setKeys(positionAnimKeys);

    scene.beginDirectAnimation(toCamera, [positionAnimation], 0, 100, false, 1, () => {
      
      fromCamera.dispose();
    });
  };

 function processDirectionalModelValues(floor: number) {
  Object.keys(directionalModelValues).forEach((key) => {
    if (key.startsWith(`f${floor}`)) {
      toogleDirectionalModel(key);
    }
  });
}

  function extractFloor(key: string): number {
    if (key.startsWith('f') && key.length >= 2) {
      return parseInt(key.charAt(1), 10); // Extract the first number after 'f'
    }
    throw new Error('Invalid key format'); // Handle invalid formats
  }

    return null;
  };

export default DirectionalModel;