import React, { useEffect, useState } from 'react';
import '../PopupInfo.css';

import Scene3D from './Scene3D';
import PopupInfo from './PopupInfo';
import ClickableIcon from './ClickableIcon';
import ProductScene3D from './product/ProductScene3D';



const ProductMainComponent: React.FC = () => {

    function handleIconClick(): void {
        throw new Error('Function not implemented.');
      }

    return (
        <div className="App">

        <div className="right">
          <ProductScene3D />
        </div>
        
        <ClickableIcon 
          iconSrc={'img/icon_back.png'} 
          altText="Clickable Icon" 
          onClick={handleIconClick} 
        />
      </div>
      );
};

export default ProductMainComponent;