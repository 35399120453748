// src/state/visibility/furnitureVisibilitySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FurnitureVisibilityState {
  value: number;
}

const initialState: FurnitureVisibilityState = {
  value: 0, // fully opaque (on)
};

const furnitureVisibilitySlice = createSlice({
  name: "furnitureVisibility",
  initialState,
  reducers: {
    toggleFurnitureVisibility: (state) => {
      state.value = state.value === 1.0 ? 0 : 1.0;
      console.log("furnitureVisibilitySlice : " + state.value);
    },
  },
});

export const { toggleFurnitureVisibility } = furnitureVisibilitySlice.actions;
export default furnitureVisibilitySlice.reducer;
