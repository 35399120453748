// src/state/store.ts
import { configureStore } from "@reduxjs/toolkit";
import transparencyReducer from "./transparency/transparencySlice";
import showAndHideReducer from "./transparency/showAndHideSlice";

import furnitureVisibilityReducer from "./visibility/furnitureVisibilitySlice";
import floorVisibilityReducer from "./visibility/floorVisibilitySlice";

import floorsTransparencyReducer from "./transparency/floorsTransparencySlice";


export const store = configureStore({
  reducer: {
    transparency: transparencyReducer,
    visibility: showAndHideReducer,
    furnitureVisibility: furnitureVisibilityReducer,
    floorVisibility: floorVisibilityReducer,
    floorsTransparency: floorsTransparencyReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
