// src/components/CustomLoadingScreen.tsx
import { ILoadingScreen, Scene } from '@babylonjs/core';
import { AdvancedDynamicTexture, Image, Control } from '@babylonjs/gui';

class CustomLoadingScreen implements ILoadingScreen {
  public loadingUIText: string;
  private scene: Scene;
  private loadingUI!: AdvancedDynamicTexture;
  private loadingImage!: Image;
  private rotationSpeed: number = 0.06; // Adjust rotation speed as needed

  // Set the background color to transparent to prevent white space
  public loadingUIBackgroundColor: string = "transparent";

  constructor(scene: Scene, loadingUIText: string) {
    this.loadingUIText = loadingUIText;
    this.scene = scene;
    this.createLoadingImage();
  }

  /**
   * Creates the loading image and sets up rotation.
   */
  private createLoadingImage() {
    // Create AdvancedDynamicTexture for the UI with transparent background
    this.loadingUI = AdvancedDynamicTexture.CreateFullscreenUI("LoadingUI", true, this.scene);
    this.loadingUI.background = this.loadingUIBackgroundColor;

    // Create the loading image
    this.loadingImage = new Image("loadingImage", "img/Logo_loading.png"); // Update the path if necessary
    this.loadingImage.width = "100px"; // Set desired width
    this.loadingImage.height = "100px"; // Set desired height
    this.loadingImage.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    this.loadingImage.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    this.loadingImage.isVisible = false; // Initially hidden
    this.loadingUI.addControl(this.loadingImage);

    // Register the rotation callback
    this.scene.registerBeforeRender(this.rotateImage);
  }

  /**
   * Rotates the loading image.
   */
  private rotateImage = () => {
    if (this.loadingImage.isVisible) {
      this.loadingImage.rotation += this.rotationSpeed;
    }
  };

  /**
   * Displays the loading UI.
   */
  displayLoadingUI() {
    this.loadingImage.isVisible = true;
  }

  /**
   * Hides the loading UI.
   */
  hideLoadingUI() {
    this.loadingImage.isVisible = false;
  }
}

export default CustomLoadingScreen;
