import React, { useEffect, useState } from 'react';
import '../ClickableIcon.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { setisDirectionalBtnClickedTransparency } from '../state/transparency/floorsTransparencySlice';

interface ClickableIconProps {
  iconSrc: string;
  altText: string;
  onClick: () => void;
}

const ClickableIcon: React.FC<ClickableIconProps> = ({ iconSrc, altText, onClick }) => {
  const dispatch = useDispatch();
  const activeModelPopup = useSelector((state: RootState) => state.floorsTransparency.activeModelPopup);
  const [showIcon, setShowIcon] = useState<boolean>(false);

  const setisDirectionalBtnClicked= () => {
    dispatch(setisDirectionalBtnClickedTransparency({ value: true})); 
  };

  const handleItemClick = () => {
    setisDirectionalBtnClicked();
  };

  
  useEffect(() => {
    // Update showIcon state based on the value of activeModelPopup
    if (activeModelPopup) {
      setShowIcon(true);
    } else {
      setShowIcon(false);
    }
  }, [activeModelPopup]);
    
  if (!showIcon) {
    return null; // Don't render anything if showIcon is false
  }

  return (
    <div className="clickable-icon-container" onClick={() => handleItemClick()}>
      <img src={iconSrc} alt={altText} className="clickable-icon" />
    </div>
  );
};

export default ClickableIcon;