// src/state/transparency/floorsTransparencySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';



interface FloorsTransparencyState {
  values: number[];
  furnitureValues: number[];
  installationValues: number[],
  heatValues: number[],
  electricityValues: number[],
  waterValues:number[]
  initialTrigger:boolean
  activeModelPopup: string,
  isDirectionalBtnClicked: boolean,
  directionalModelValues: { [key: string]: number },
  activeDirectionalModel: string,
  productTextures: { [key: string]: string },
  environmentValues: number[]
}

const initialState: FloorsTransparencyState = {
  values: [0 ,0, 0], // Initial state with all floors visible
  furnitureValues: [0,0,0],
  installationValues: [0,0,0],
  heatValues: [0,0,0],
  electricityValues:[0,0,0],
  waterValues: [0,0,0],
  initialTrigger:false,
  activeModelPopup:"",
  isDirectionalBtnClicked:false,
  directionalModelValues: {
    f11: 0,
    f12: 0,
    f13: 0,
    f14: 0,
    f15: 0,
    f21: 0,
    f22: 0,
    f23: 0,
    f24: 0,
    f25: 0,
  },
  activeDirectionalModel: "",
  productTextures:{
    test : "test"
  },
  environmentValues:[0,0,0]
};

const floorsTransparencySlice = createSlice({
  name: 'floorsTransparency',
  initialState,
  reducers: {
    //Floors methods
    setFloorTransparency: (state, action: PayloadAction<{ floor: number; value: number }>) => {
      const { floor, value } = action.payload;
      if (floor >= 0 && floor < state.values.length) {
        state.values[floor] = value;
      }
    },
    toggleFloorTransparency: (state, action: PayloadAction<number>) => {
      const floor = action.payload;
      if (floor >= 0 && floor < state.values.length) {
        state.values[floor] = state.values[floor] === 1 ? 0 : 1;
      }
    },

    //Furniture methods
    setFurnitureTransparency: (state, action: PayloadAction<{ floor: number; value: number }>) => {
      const { floor, value } = action.payload;
      if (floor >= 0 && floor < state.furnitureValues.length) {
        state.furnitureValues[floor] = value;
      }
    },
    toggleFurnitureTransparency: (state, action: PayloadAction<number>) => {
      const floor = action.payload;
      if (floor >= 0 && floor < state.furnitureValues.length) {
        state.furnitureValues[floor] = state.furnitureValues[floor] === 1 ? 0 : 1;
      }
    },


     //Environment methods
     setEnvironmentTransparency: (state, action: PayloadAction<{ floor: number; value: number }>) => {
      const { floor, value } = action.payload;
      if (floor >= 0 && floor < state.environmentValues.length) {
        state.environmentValues[floor] = value;
      }
    },
    toggleEnvironmentTransparency: (state, action: PayloadAction<number>) => {
      const floor = action.payload;
      if (floor >= 0 && floor < state.environmentValues.length) {
        state.environmentValues[floor] = state.environmentValues[floor] === 1 ? 0 : 1;
      }
    },

    // Installation methods
    setInstallationTransparency: (state, action: PayloadAction<{ floor: number; value: number }>) => {
      const { floor, value } = action.payload;
      if (floor >= 0 && floor < state.installationValues.length) {
        state.installationValues[floor] = value;
      }
    },
    toggleInstallationTransparency: (state, action: PayloadAction<number>) => {
      const floor = action.payload;
      if (floor >= 0 && floor < state.installationValues.length) {
        state.installationValues[floor] = state.installationValues[floor] === 1 ? 0.5 : 1;
      }
    },

    // Heat methods
    setHeatTransparency: (state, action: PayloadAction<{ floor: number; value: number }>) => {
      const { floor, value } = action.payload;
      if (floor >= 0 && floor < state.heatValues.length) {
        state.heatValues[floor] = value;
      }
    },
    toggleHeatTransparency: (state, action: PayloadAction<number>) => {
      const floor = action.payload;
      if (floor >= 0 && floor < state.heatValues.length) {
        state.heatValues[floor] = state.heatValues[floor] === 1 ? 0 : 1;
      }
    },

    // Electricity methods
    setElectricityTransparency: (state, action: PayloadAction<{ floor: number; value: number }>) => {
      const { floor, value } = action.payload;
      if (floor >= 0 && floor < state.electricityValues.length) {
        state.electricityValues[floor] = value;
      }
    },
    toggleElectricityTransparency: (state, action: PayloadAction<number>) => {
      const floor = action.payload;
      if (floor >= 0 && floor < state.electricityValues.length) {
        state.electricityValues[floor] = state.electricityValues[floor] === 1 ? 0 : 1;
      }
    },

     // Water methods
    setWaterTransparency: (state, action: PayloadAction<{ floor: number; value: number }>) => {
      const { floor, value } = action.payload;
      if (floor >= 0 && floor < state.waterValues.length) {
        state.waterValues[floor] = value;
      }
    },
    toggleWaterTransparency: (state, action: PayloadAction<number>) => {
      const floor = action.payload;
      if (floor >= 0 && floor < state.waterValues.length) {
        state.waterValues[floor] = state.waterValues[floor] === 1 ? 0 : 1;
      }
    },

     // Initial trigger methods
    setInitialTriggerTransparency: (state, action: PayloadAction<{  value: boolean }>) => {
      const {  value } = action.payload;
        state.initialTrigger= value;
    },
    toggleInitialTriggerTransparency: (state, action: PayloadAction<boolean>) => {
      const initialTrigger = action.payload;
        state.initialTrigger = initialTrigger === true ? false : true;
    },

     // Initial activeModelPopup methods
    setInitialActiveModelPopup: (state, action: PayloadAction<string>) => {
      const value  = action.payload;
        state.activeModelPopup= value;
    },
    changeInitialActiveModelPopup: (state, action: PayloadAction<string>) => {
      const activeModelPopup = action.payload;
        state.activeModelPopup = activeModelPopup;
    },


    // Initial trigger methods
    setisDirectionalBtnClickedTransparency: (state, action: PayloadAction<{  value: boolean }>) => {
      const {  value } = action.payload;
        state.isDirectionalBtnClicked= value;
    },
    toggleisDirectionalBtnClickedTransparency: (state, action: PayloadAction<boolean>) => {
      const isDirectionalBtnClicked = action.payload;
        state.isDirectionalBtnClicked = isDirectionalBtnClicked === true ? false : true;
    },

    setDirectionalModelValue: (
      state,
      action: PayloadAction<{ key: string; value: number }>
    ) => {
      const { key, value } = action.payload;
      state.directionalModelValues[key] = value;
    },
    toogleDirectionalModelValue: (
      state,
      action: PayloadAction<string>
    ) => {
      const key = action.payload;
      state.directionalModelValues[key] = state.directionalModelValues[key] === 1 ? 0 : 1;
    },

    // Initial activeModelPopup methods
    setActiveDirectionalModel: (state, action: PayloadAction<string>) => {
      const value  = action.payload;
      state.activeDirectionalModel= value;
    },



    setProductTextures: (
      state,
      action: PayloadAction<{ key: string; value: string }>
    ) => {
      const { key, value } = action.payload;
      state.productTextures[key] = value;
    }
   


  },
});

export const { setFloorTransparency, toggleFloorTransparency,setFurnitureTransparency,toggleFurnitureTransparency,setInstallationTransparency,toggleInstallationTransparency, setHeatTransparency, toggleHeatTransparency,setElectricityTransparency, toggleElectricityTransparency,setWaterTransparency,toggleWaterTransparency, setInitialTriggerTransparency, toggleInitialTriggerTransparency, setInitialActiveModelPopup, changeInitialActiveModelPopup,setisDirectionalBtnClickedTransparency,toggleisDirectionalBtnClickedTransparency, setDirectionalModelValue, toogleDirectionalModelValue, setActiveDirectionalModel,setProductTextures,toggleEnvironmentTransparency,setEnvironmentTransparency } = floorsTransparencySlice.actions;
export default floorsTransparencySlice.reducer;
