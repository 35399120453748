// Gallery.tsx
import React, { useEffect, useState } from 'react';
import '../DrawingGallery.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';
import { setisDirectionalBtnClickedTransparency } from '../state/transparency/floorsTransparencySlice';

interface DrawingGalleryProps {
  iconSrc: string;
  altText: string;
  images: string[]; // Array of image URLs for the gallery
}

const DrawingGallery: React.FC<DrawingGalleryProps> = ({ iconSrc, altText, images }) => {
  const dispatch = useDispatch();
  const activeModelPopup = useSelector((state: RootState) => state.floorsTransparency.activeModelPopup);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const setisDirectionalBtnClicked = () => {
    dispatch(setisDirectionalBtnClickedTransparency({ value: true })); 
  };

  const handleIconClick = () => {
    setisDirectionalBtnClicked();
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    // Update showIcon state based on the value of activeModelPopup
    setShowIcon(true);
  }, [activeModelPopup]);

  if (!showIcon) {
    return null; // Don't render anything if showIcon is false
  }

  return (
    <>
      {/* Gallery Icon */}
      <div className="drawing-gallery-icon-container" onClick={handleIconClick}>
        <img src={iconSrc} alt={altText} className="gallery-icon" />
      </div>

      {/* Popup Gallery */}
      {isPopupOpen && (
        <div className="drawing-gallery-popup-overlay" onClick={handleClosePopup}>
          <div className="gallery-popup" onClick={(e) => e.stopPropagation()}>
            <button className="gallery-popup-close" onClick={handleClosePopup}>
              &times;
            </button>
            <div className="gallery-images">
              {images.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Gallery Image ${index + 1}`}
                  className="gallery-popup-image"
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DrawingGallery;
