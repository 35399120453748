// src/components/Scene3D.tsx
import React, { useEffect, useRef, useState } from 'react';
import {
  Color3,
  Color4,
  CubeTexture,
  Engine,
  LinesMesh,
  Matrix,
  Mesh,
  MeshBuilder,
  PBRMaterial,
  PointerDragBehavior,
  PointerEventTypes,
  PointerInfo,
  Scene,
  StandardMaterial,
  ArcRotateCamera,
  HemisphericLight,
  Vector3
} from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import GenericModel from './GenericModel';
import CustomLoadingScreen from './CustomLoadingScreen';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../state/store';
import {
  setElectricityTransparency,
  setEnvironmentTransparency,
  setFloorTransparency,
  setFurnitureTransparency,
  setHeatTransparency,
  setInitialTriggerTransparency,
  setInstallationTransparency,
  setWaterTransparency,
  toogleDirectionalModelValue,
} from '../state/transparency/floorsTransparencySlice';
import '../App.css';
import DirectionalModel from './DirectionalModel';
import { AdvancedDynamicTexture, TextBlock } from '@babylonjs/gui';

interface MeasurementLabel {
  textBlock: TextBlock;
  midpoint: Vector3;
  labelPlane: Mesh;
}

const Scene3D: React.FC = () => {
  const dispatch = useDispatch();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [scene, setScene] = useState<Scene | null>(null);
  const [camera, setCamera] = useState<ArcRotateCamera | null>(null);
  const [engine, setEngine] = useState<Engine | null>(null);

  const floorsTransparency = useSelector(
    (state: RootState) => state.floorsTransparency.values
  );

  const furnitureVisibility = useSelector(
    (state: RootState) => state.floorsTransparency.furnitureValues
  );
  const installationTransparency = useSelector(
    (state: RootState) => state.floorsTransparency.installationValues
  );
  const heatTransparency = useSelector(
    (state: RootState) => state.floorsTransparency.heatValues
  );
  const electricityTransparency = useSelector(
    (state: RootState) => state.floorsTransparency.electricityValues
  );
  const waterTransparency = useSelector(
    (state: RootState) => state.floorsTransparency.waterValues
  );
  const environmentVisibility = useSelector(
    (state: RootState) => state.floorsTransparency.environmentValues
  );

  const activeModelPopup = useSelector(
    (state: RootState) => state.floorsTransparency.activeModelPopup
  );

  const [apartmentData, setApartmentData] = useState<{
    description: string;
  } | null>(null);

  const [infoModelData, setInfoModelData] = useState<{
    description: string;
  } | null>(null);

  // State for measurement functionality
  const [isMeasurementEnabled, setIsMeasurementEnabled] = useState(false);
  const [unit, setUnit] = useState<'units' | 'meters' | 'feet'>('units');

  const conversionFactors = {
    units: 1,
    meters: 1,
    feet: 3.28084,
  };

  // Arrays to hold measurement data
  const points: Vector3[] = [];
  const pointMeshes: Mesh[] = [];
  const lines: LinesMesh[] = [];
  const measurementLabels: MeasurementLabel[] = [];

  // Ref to store AdvancedDynamicTexture to manage its lifecycle
  const advancedTextureRef = useRef<AdvancedDynamicTexture | null>(null);

  // Fetch apartment data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://animodapi.azurewebsites.net/Apartment/GetApartment?id=Apartment-Demo`
        );
        const data = await response.json();
        setApartmentData({
          description: data.description,
        });
      } catch (error) {
        console.error('Error fetching apartment data:', error);
      }
    };

    fetchData();
  }, []);

  // Fetch model popup data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://animodapi.azurewebsites.net/Model/GetModel?id=${activeModelPopup}`
        );
        const data = await response.json();
        setInfoModelData({
          description: data.description,
        });
      } catch (error) {
        console.error('Error fetching model popup data:', error);
      }
    };

    if (activeModelPopup) {
      fetchData();
    }
  }, [activeModelPopup]);

  // Initialize BabylonJS Engine and Scene
  useEffect(() => {
    if (!canvasRef.current) return;

    const engineInstance = new Engine(canvasRef.current, true);
    setEngine(engineInstance);

    const sceneInstance = new Scene(engineInstance);
    setScene(sceneInstance);

    const customLoadingScreen = new CustomLoadingScreen(sceneInstance, 'Loading...');
    engineInstance.loadingScreen = customLoadingScreen;

    engineInstance.displayLoadingUI();

    const loadingTimer = setTimeout(() => {
      engineInstance.hideLoadingUI();
    }, 3100); // 3.1 seconds delay

    // Load HDRI and set it as the environment texture
    async function loadCubeTextures(scene: Scene): Promise<void> {
      try {
        const hdrTexture = CubeTexture.CreateFromPrefilteredData(
          'https://animodstorage.blob.core.windows.net/hdr/scene.env',
          scene
        );
        const pozadinaTexture = CubeTexture.CreateFromPrefilteredData(
          'https://animodstorage.blob.core.windows.net/hdr/pozadina.env',
          scene
        );

        // Create skybox
        const skybox = MeshBuilder.CreateBox('skyBox', { size: 1000.0 }, scene);
        const skyboxMaterial = new StandardMaterial('skyBox', scene);
        skyboxMaterial.backFaceCulling = false;
        skyboxMaterial.reflectionTexture = pozadinaTexture.clone();
        skyboxMaterial.reflectionTexture.coordinatesMode = 5;
        skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
        skyboxMaterial.specularColor = new Color3(0, 0, 0);
        skybox.material = skyboxMaterial;

        // Create a reflective cube
        const cube = MeshBuilder.CreateBox('cube', { size: 1000.0 }, scene);
        const cubeMaterial = new PBRMaterial('cube', scene);
        cubeMaterial.reflectionTexture = hdrTexture.clone();
        cubeMaterial.reflectionTexture.coordinatesMode = 3;
        cubeMaterial.cameraExposure = 0.66;
        cubeMaterial.cameraContrast = 1.66;
        cubeMaterial.microSurface = 0.96;
        cube.material = cubeMaterial;

        cube.position = new Vector3(0, 1, 0);

        scene.environmentTexture = hdrTexture;
      } catch (error) {
        console.error('Error loading textures:', error);
      }
    }

    loadCubeTextures(sceneInstance);

    const cameraInstance = new ArcRotateCamera(
      'camera1',
      Math.PI / 1.3,
      Math.PI / 4,
      12.8,
      Vector3.Zero(),
      sceneInstance
    );

    cameraInstance.useAutoRotationBehavior = true;
    cameraInstance.attachControl(canvasRef.current, true);
    cameraInstance.wheelPrecision = 150;
    cameraInstance.noRotationConstraint = true;

    sceneInstance.collisionsEnabled = true;
    setCamera(cameraInstance);

    new HemisphericLight('light1', new Vector3(0, 1, 0), sceneInstance);

    engineInstance.runRenderLoop(() => {
      sceneInstance.render();
    });

    const handleResize = () => {
      engineInstance.resize();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      clearTimeout(loadingTimer);
      window.removeEventListener('resize', handleResize);
      engineInstance.dispose(); // Dispose engine and scene on component unmount
    };
  }, []);

  // Measurement UI Management
  useEffect(() => {
    if (scene && camera && engine) {
      if (isMeasurementEnabled) {
        // Initialize AdvancedDynamicTexture
        const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI('MeasurementUI', true, scene);
        advancedTextureRef.current = advancedTexture;

        // Define the before render callback
        const beforeRenderCallback = () => {
          measurementLabels.forEach((label) => {
            if (scene.activeCamera && advancedTexture) {
              const screenPosition = Vector3.Project(
                label.midpoint,
                Matrix.Identity(),
                scene.getTransformMatrix(),
                camera.viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight())
              );

              const leftPercent = (screenPosition.x / engine.getRenderWidth()) * 100;
              const topPercent = (screenPosition.y / engine.getRenderHeight()) * 100;
              const yOffsetPercent = -5;

              label.textBlock.left = `${leftPercent}%`;
              label.textBlock.top = `${topPercent + yOffsetPercent}%`;
            }
          });
        };

        // Register the before render callback
        scene.registerBeforeRender(beforeRenderCallback);

        // Register pointer down event
        const pointerObserver = scene.onPointerObservable.add((pointerInfo: PointerInfo) => {
          if (pointerInfo.type === PointerEventTypes.POINTERDOWN && isMeasurementEnabled) {
            const pickResult = pointerInfo.pickInfo;
            if (pickResult && pickResult.pickedPoint) {
              // Check if the left mouse button was clicked (button === 0)
              if (pointerInfo.event.button === 0) {
                addPoint(pickResult.pickedPoint, scene);
              }
            }
          }
        });

        // Prevent default behaviors for middle and right clicks
        const preventMiddleRightClick = (e: MouseEvent) => {
          if (e.button === 1 || e.button === 2) {
            e.preventDefault();
          }
        };

        const preventContextMenu = (e: MouseEvent) => {
          e.preventDefault();
        };

        if (canvasRef.current) {
          canvasRef.current.addEventListener('mousedown', preventMiddleRightClick);
          canvasRef.current.addEventListener('contextmenu', preventContextMenu);
        }

        // Cleanup when measurement is disabled
        return () => {
          // Unregister the before render callback
          scene.unregisterBeforeRender(beforeRenderCallback);

          // Remove pointer observer
          scene.onPointerObservable.remove(pointerObserver);

          // Dispose of the AdvancedDynamicTexture
          advancedTexture.dispose();
          advancedTextureRef.current = null;

          // Remove event listeners from the canvas
          if (canvasRef.current) {
            canvasRef.current.removeEventListener('mousedown', preventMiddleRightClick);
            canvasRef.current.removeEventListener('contextmenu', preventContextMenu);
          }

          // Reset all measurements
          resetMeasurements();
        };
      }
    }
  }, [isMeasurementEnabled, scene, camera, engine]);

  // Function to toggle directional models
  const toogleDirectionalModel = (key: string) => {
    dispatch(toogleDirectionalModelValue(key));
  };

  // Functions to set various transparencies and visibilities
  const setInitialTrigger = () => {
    dispatch(setInitialTriggerTransparency({ value: true }));
  };

  const setTransparencyForFirstFloor = () => {
    dispatch(setFloorTransparency({ floor: 1, value: floorsTransparency[1] === 1 ? 0 : 1 }));
  };

  const setTransparencyForSecondFloor = () => {
    dispatch(setFloorTransparency({ floor: 2, value: floorsTransparency[2] === 1 ? 0 : 1 }));
  };

  // Furniture
  const setFurnitureVisibilityForFirstFloor = () => {
    dispatch(setFurnitureTransparency({ floor: 1, value: furnitureVisibility[1] === 1 ? 0 : 1 }));
  };

  const setFurnitureVisibilityForSecondFloor = () => {
    dispatch(setFurnitureTransparency({ floor: 2, value: furnitureVisibility[2] === 1 ? 0 : 1 }));
  };

  // Environment
  const setEnvironmentVisibilityForFirstFloor = () => {
    dispatch(setEnvironmentTransparency({ floor: 1, value: environmentVisibility[1] === 1 ? 0 : 1 }));
  };

  const setEnvironmentVisibilityForSecondFloor = () => {
    dispatch(setEnvironmentTransparency({ floor: 2, value: environmentVisibility[2] === 1 ? 0 : 1 }));
  };

  // Installation
  const setInstallationVisibilityForFirstFloor = () => {
    dispatch(setInstallationTransparency({ floor: 1, value: installationTransparency[1] === 1 ? 0.5 : 1 }));
  };

  const setInstallationVisibilityForSecondFloor = () => {
    dispatch(setInstallationTransparency({ floor: 2, value: installationTransparency[2] === 1 ? 0.5 : 1 }));
  };

  // Heat
  const setHeatVisibilityForFirstFloor = () => {
    dispatch(setHeatTransparency({ floor: 1, value: heatTransparency[1] === 1 ? 0 : 1 }));
  };

  const setHeatVisibilityForSecondFloor = () => {
    dispatch(setHeatTransparency({ floor: 2, value: heatTransparency[2] === 1 ? 0 : 1 }));
  };

  // Electricity
  const setElectricityVisibilityForFirstFloor = () => {
    dispatch(setElectricityTransparency({ floor: 1, value: electricityTransparency[1] === 1 ? 0 : 1 }));
  };

  const setElectricityVisibilityForSecondFloor = () => {
    dispatch(setElectricityTransparency({ floor: 2, value: electricityTransparency[2] === 1 ? 0 : 1 }));
  };

  // Water
  const setWaterVisibilityForFirstFloor = () => {
    dispatch(setWaterTransparency({ floor: 1, value: waterTransparency[1] === 1 ? 0 : 1 }));
  };

  const setWaterVisibilityForSecondFloor = () => {
    dispatch(setWaterTransparency({ floor: 2, value: waterTransparency[2] === 1 ? 0 : 1 }));
  };

  // useEffect to set initial states with a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setTransparencyForFirstFloor();
      // setTransparencyForSecondFloor(); // Uncomment if needed

      setFurnitureVisibilityForFirstFloor();
      setFurnitureVisibilityForSecondFloor();

      // setEnvironmentVisibilityForFirstFloor(); // Uncomment if needed

      setInstallationVisibilityForFirstFloor();
      setInstallationVisibilityForSecondFloor();

      setInitialTrigger();

      toogleDirectionalModel('f11');
      toogleDirectionalModel('f12');
      toogleDirectionalModel('f13');
      toogleDirectionalModel('f14');
      toogleDirectionalModel('f15');

      toogleDirectionalModel('f21');
      toogleDirectionalModel('f22');
    }, 2500); // 2.5 seconds delay

    // Cleanup the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  // Measurement Functions

  const addPoint = (position: Vector3, scene: Scene) => {
    if (points.length >= 2) {
      // Reset if already two points are present
      resetMeasurements();
    }

    // Create a sphere to represent the point
    const sphere = MeshBuilder.CreateSphere('point', { diameter: 0.02 }, scene);
    sphere.position = position;
    const pointMaterial = new StandardMaterial('pointMat', scene);
    pointMaterial.diffuseColor = new Color3(1, 0, 0); // Red Color
    sphere.material = pointMaterial;
    pointMeshes.push(sphere);
    points.push(position.clone());

    // Add drag behavior
    const dragBehavior = new PointerDragBehavior();
    sphere.addBehavior(dragBehavior);

    dragBehavior.onDragObservable.add(() => {
      updateMeasurementLinesAndLabels();
    });

    if (points.length === 2) {
      createMeasurement(scene);
    }
  };

  const createMeasurement = (scene: Scene) => {
    if (points.length !== 2) return;

    // Create a line between the two points with Color4
    const measurementLine = MeshBuilder.CreateLines(
      'measurementLine',
      {
        points: [points[0], points[1]],
        colors: [
          new Color4(1, 0, 0, 1), // Red color with full opacity
          new Color4(1, 0, 0, 1),
        ],
      },
      scene
    );
    lines.push(measurementLine);

    // Calculate distance
    const distance = Vector3.Distance(points[0], points[1]);

    // Calculate midpoint
    const midPoint = points[0].add(points[1]).scale(0.5);

    // Create and configure TextBlock for distance label
    const distanceLabel = new TextBlock();
    distanceLabel.text = `${distance.toFixed(2)} ${unit}`;
    distanceLabel.color = 'white';
    distanceLabel.fontSize = 24;
    distanceLabel.paddingTop = '5px';
    distanceLabel.paddingBottom = '5px';
    distanceLabel.paddingLeft = '10px';
    distanceLabel.paddingRight = '10px';
    distanceLabel.textHorizontalAlignment = TextBlock.HORIZONTAL_ALIGNMENT_CENTER;
    distanceLabel.textVerticalAlignment = TextBlock.VERTICAL_ALIGNMENT_CENTER;

    // Add TextBlock to AdvancedDynamicTexture
    advancedTextureRef.current?.addControl(distanceLabel);

    // Create a plane to link with the TextBlock
    const labelPlane = MeshBuilder.CreatePlane('labelPlane', { size: 2 }, scene);
    labelPlane.position = midPoint.add(new Vector3(0, 0.25, 0)); // Adjust Y offset as needed
    labelPlane.billboardMode = Mesh.BILLBOARDMODE_ALL; // Make the plane face the camera

    // Make the plane transparent
    const planeMaterial = new StandardMaterial('planeMat', scene);
    planeMaterial.diffuseColor = new Color3(0, 0, 0); // Color doesn't matter
    planeMaterial.alpha = 0; // Fully transparent
    labelPlane.material = planeMaterial;

    // Link the TextBlock with the labelPlane
    distanceLabel.linkWithMesh(labelPlane);
    distanceLabel.linkOffsetY = 50; // Adjust to position the label correctly

    // Store the label and its plane for future reference
    measurementLabels.push({ textBlock: distanceLabel, midpoint: midPoint, labelPlane });
  };

  const updateMeasurementLinesAndLabels = () => {
    measurementLabels.forEach((label, index) => {
      if (lines[index] && pointMeshes[index * 2] && pointMeshes[index * 2 + 1]) {
        // Update line points
        lines[index].dispose();
        lines[index] = MeshBuilder.CreateLines(
          `measurementLine_${index}`,
          {
            points: [pointMeshes[index * 2].position, pointMeshes[index * 2 + 1].position],
            colors: [
              new Color4(1, 0, 0, 1),
              new Color4(1, 0, 0, 1),
            ],
          },
          scene!
        );

        // Recalculate distance and midpoint
        const distance = Vector3.Distance(
          pointMeshes[index * 2].position,
          pointMeshes[index * 2 + 1].position
        );
        const midPoint = pointMeshes[index * 2].position
          .add(pointMeshes[index * 2 + 1].position)
          .scale(0.5);

        // Update label text and midpoint
        label.textBlock.text = `${distance.toFixed(2)} ${unit}`;
        label.midpoint = midPoint;
      }
    });
  };

  const resetMeasurements = () => {
    // Dispose of point meshes
    pointMeshes.forEach((mesh) => mesh.dispose());
    pointMeshes.length = 0;
    points.length = 0;

    // Dispose of lines
    lines.forEach((line) => line.dispose());
    lines.length = 0;

    // Remove and dispose of measurement labels and their planes
    measurementLabels.forEach((label) => {
      advancedTextureRef.current?.removeControl(label.textBlock);
      label.labelPlane.dispose();
    });
    measurementLabels.length = 0;
  };

  const toggleMeasurement = () => {
    setIsMeasurementEnabled((prev) => !prev);
    if (isMeasurementEnabled) {
      // Optionally, reset measurements when disabling
      resetMeasurements();
    }
  };

  // Function to handle unit changes
  useEffect(() => {
    measurementLabels.forEach((label, index) => {
      if (points[index * 2] && points[index * 2 + 1]) {
        const distance = Vector3.Distance(points[index * 2], points[index * 2 + 1]);
        label.textBlock.text = `${distance.toFixed(2)} ${unit}`;
      }
    });
  }, [unit]);

  // Functions to handle expandable list items
  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({
    'Floor 1': true,
    'Floor 2': false,
  });

  const [checkedSubItems, setCheckedSubItems] = useState<{ [key: string]: boolean }>({
    'Floor 1-Furniture': true,
    'Floor 1-Electricity': false,
    'Floor 1-Water': false,
    'Floor 1-Heat': false,
    'Floor 1-Environment': false,
    'Floor 2-Furniture': true,
    'Floor 2-Electricity': false,
    'Floor 2-Water': false,
    'Floor 2-Heat': false,
    'Floor 2-Environment': false,
  });

  const items = [
    {
      name: 'Floor 1',
      icon: 'img/icon_first.png',
      subItems: [
        { name: 'Furniture', icon: 'img/icon_furniture.png' },
        { name: 'Electricity', icon: 'img/icon_electricity.png' },
        { name: 'Water', icon: 'img/icon_water.png' },
        { name: 'Heat', icon: 'img/icon_heat.png' },
        { name: 'Environment', icon: 'img/icon_env_off.png' },
      ],
    },
    {
      name: 'Floor 2',
      icon: 'img/icon_second.png',
      subItems: [
        { name: 'Furniture', icon: 'img/icon_furniture.png' },
        { name: 'Electricity', icon: 'img/icon_electricity.png' },
        { name: 'Water', icon: 'img/icon_water.png' },
        { name: 'Heat', icon: 'img/icon_heat.png' },
        { name: 'Environment', icon: 'img/icon_env_off.png' },
      ],
    },
  ];

  const handleItemClick = (itemName: string) => {
    setExpandedItems((prevExpandedItems) => {
      const isExpanded = !prevExpandedItems[itemName];

      // Schedule the state update after the render phase
      setTimeout(() => {
        if (itemName === 'Floor 1') {
          setTransparencyForFirstFloor();
        } else if (itemName === 'Floor 2') {
          setTransparencyForSecondFloor();
        }
      }, 0);

      return {
        ...prevExpandedItems,
        [itemName]: isExpanded,
      };
    });
  };

  const handleSubItemClick = (mainItemName: string, subItemName: string) => {
    setCheckedSubItems((prevCheckedSubItems) => {
      const isChecked = !prevCheckedSubItems[`${mainItemName}-${subItemName}`];

      // Schedule the state update after the render phase
      setTimeout(() => {
        if (mainItemName === 'Floor 1') {
          switch (subItemName) {
            case 'Furniture':
              setFurnitureVisibilityForFirstFloor();
              break;
            case 'Electricity':
              setElectricityVisibilityForFirstFloor();
              break;
            case 'Water':
              setWaterVisibilityForFirstFloor();
              break;
            case 'Heat':
              setHeatVisibilityForFirstFloor();
              break;
            case 'Environment':
              setEnvironmentVisibilityForFirstFloor();
              break;
            default:
              break;
          }
        } else if (mainItemName === 'Floor 2') {
          switch (subItemName) {
            case 'Furniture':
              setFurnitureVisibilityForSecondFloor();
              break;
            case 'Electricity':
              setElectricityVisibilityForSecondFloor();
              break;
            case 'Water':
              setWaterVisibilityForSecondFloor();
              break;
            case 'Heat':
              setHeatVisibilityForSecondFloor();
              break;
            case 'Environment':
              setEnvironmentVisibilityForSecondFloor();
              break;
            default:
              break;
          }
        }
      }, 0);

      return {
        ...prevCheckedSubItems,
        [`${mainItemName}-${subItemName}`]: isChecked,
      };
    });
  };

  // Images
  const eyeClosed = 'img/eye-closed.png';
  const eyeOpen = 'img/eye-open.png';
  const iconElectricity = 'img/icon_electricity.png';
  const iconWater = 'img/icon_water.png';
  const iconHeat = 'img/icon_heat.png';
  const iconFurniture = 'img/icon_furniture.png';
  const firstFloor = 'img/icon_first.png';
  const secondFloor = 'img/icon_second.png';

  const iconEnvironmentOn = 'img/icon_env_on.png';
  const iconEnvironmentOff = 'img/icon_env_off.png';

  const measurementEnabledIcon = 'img/measurement_enabled.png'; // Replace with your actual path
const measurementDisabledIcon = 'img/measurement_disabled.png'; // Replace with your actual path


  return (
    <>
      <canvas ref={canvasRef} />

      {scene && camera && (
        <>
          {/* First Floor Models */}
          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="00_Grid_scene.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_AC.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_Electricity.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={true}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_Furniture.glb"
            floor={1}
            hasFurniture={true}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_Heat.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={true}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_Walls.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={true}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_Water.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={true}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_Construction.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_Neighbor.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={true}
          />

          {/* Second Floor Models */}
          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_AC.glb"
            floor={2}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_Water.glb"
            floor={2}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={true}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_Walls.glb"
            floor={2}
            hasFurniture={false}
            hasInstallation={true}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_Heat.glb"
            floor={2}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={true}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_Electricity.glb"
            floor={2}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={true}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_Furniture.glb"
            floor={2}
            hasFurniture={true}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_Construction.glb"
            floor={2}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_Neighbor.glb"
            floor={2}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={true}
          />

          {/* Directional Models First Floor */}
          <DirectionalModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            floor={1}
            targetedModel="cam_01_dot"
            directionalPositionMesh="cam_01_dot"
            directionalModelKey="f11"
          />
          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_cam_01_Living_room.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <DirectionalModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            floor={1}
            targetedModel="cam_02_dot"
            directionalPositionMesh="cam_02_dot"
            directionalModelKey="f12"
          />
          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_cam_02_Dining_room.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <DirectionalModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            floor={1}
            targetedModel="cam_03_dot"
            directionalPositionMesh="cam_03_dot"
            directionalModelKey="f13"
          />
          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_cam_03_Kitchen.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <DirectionalModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            floor={1}
            targetedModel="cam_04_dot"
            directionalPositionMesh="cam_04_dot"
            directionalModelKey="f14"
          />
          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_cam_04_Entrance.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <DirectionalModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            floor={1}
            targetedModel="cam_05_dot"
            directionalPositionMesh="cam_05_dot"
            directionalModelKey="f15"
          />
          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="01_cam_05_WC.glb"
            floor={1}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          {/* Directional Models Second Floor */}
          <DirectionalModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            floor={2}
            targetedModel="02_cam_01_dot"
            directionalPositionMesh="02_cam_01_dot"
            directionalModelKey="f21"
          />
          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_cam_01_Sleeping_room.glb"
            floor={2}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />

          <DirectionalModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            floor={2}
            targetedModel="02_cam_02_dot"
            directionalPositionMesh="02_cam_02_dot"
            directionalModelKey="f22"
          />
          <GenericModel
            scene={scene}
            camera={camera}
            canvas={canvasRef.current}
            model="02_cam_02_WC.glb"
            floor={2}
            hasFurniture={false}
            hasInstallation={false}
            hasHeat={false}
            hasElectricity={false}
            hasWater={false}
            isDirectional={false}
            targetedModel=""
            hasEnvironment={false}
          />
        </>
      )}

      {/* Expandable List */}
      <div className="expandable-list">
        {items.map((item, index) => (
          <div key={index} className="item">
            <button className="item-button" onClick={() => handleItemClick(item.name)}>
              <img src={item.icon} alt="Item Icon" className="item-icon" />
              {item.name}
              <span className={`expand-icon ${expandedItems[item.name] ? 'expanded' : ''}`}>
                ▼
              </span>
            </button>
            {expandedItems[item.name] && (
              <div className="subitems">
                {item.subItems.map((subItem, subIndex) => (
                  <div key={subIndex} className="subitem-container">
                    <a
                      className="subitem"
                      onClick={() => handleSubItemClick(item.name, subItem.name)}
                    >
                      <img src={subItem.icon} alt="Subitem Icon" className="subitem-icon" />
                      {subItem.name}
                    </a>
                    <img
                      src={checkedSubItems[`${item.name}-${subItem.name}`] ? eyeOpen : eyeClosed}
                      alt="Eye Icon"
                      className="eye-icon"
                      onClick={() => handleSubItemClick(item.name, subItem.name)}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Measurement Controls */}
      <div>
        {/* Measurement Toggle Button */}
        <button
          onClick={toggleMeasurement}
          className='measurment-btn'
          
          >
          <img
          className='measurment-icon'
            src={isMeasurementEnabled ? measurementEnabledIcon : measurementDisabledIcon}
            alt={isMeasurementEnabled ? 'Disable Measurement' : 'Enable Measurement'}

          />
          </button>

        {/* Measurement Units Dropdown */}
        <select
        className='measurment-unit'
          value={unit}
          onChange={(e) => setUnit(e.target.value as 'units' | 'meters' | 'feet')}

        >
          <option value="units">Units</option>
          <option value="meters">Meters</option>
          <option value="feet">Feet</option>
        </select>
      </div>
    </>
  );
};

export default Scene3D;
