// src/state/visibility/floorVisibilitySlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FloorVisibilityState {
  value: number;
}

const initialState: FloorVisibilityState = {
  value: 0, // fully opaque (on)
};

const floorVisibilitySlice = createSlice({
  name: "floorVisibility",
  initialState,
  reducers: {
    toggleFloorVisibility: (state) => {
      state.value = state.value === 1.0 ? 0 : 1.0;
      console.log("floorVisibilitySlice : " + state.value);
    },
  },
});

export const { toggleFloorVisibility } = floorVisibilitySlice.actions;
export default floorVisibilitySlice.reducer;
