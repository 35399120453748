import React, { useEffect, useState } from 'react';
import '../LocationInfo.css';

interface LocationInfoProps {
  title: string;
}

const LocationInfo: React.FC<LocationInfoProps> = ({ title }) => {
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await fetch('https://animodapi.azurewebsites.net/Location/GetDefault');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setDescription(data.description);
      } catch (error) {
        console.error('Error fetching location data:', error);
        setDescription('Location information unavailable.');
      }
    };

    fetchLocationData();
  }, []);

  return (
    <div className="location-info">
      <span className="location-info-title">{title} </span>
  {/*     <span
        className="location-info-description"
        title={description} // Tooltip on hover to show full text
        dangerouslySetInnerHTML={{ __html: description }}
      /> */}
    </div>
  );
};

export default LocationInfo;
